import React from "react";
import { useSearchParams } from "react-router-dom";
import PostcardContent from "../components/PostcardContent";
import logo from "../images/logo.svg";

export default() => {

    const [searchParams] = useSearchParams();

    return (
        <div className="full-page">  
            <div className="postcard">
                <div className="to-section">
                    <img className="big-logo" src={logo}/>
                </div>
                <PostcardContent
                    content={searchParams.get("content")}
                    
                />
            </div>

        </div>
    );
};  