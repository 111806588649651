import React from "react";
import moment from "moment";

const DATES = [
    {id: "weeks", title: "1 Week", date: moment().add(1, "weeks")},
    {id: "months", title: "1 Month", date: moment().add(1, "months")},
    {id: "years", title: "1 Year", date: moment().add(1, "years")}
]

export default({date, onChangeDate}) => {
    return (
        <div className="date-options">
            {DATES.map((currentDate) => {
                const isSelected = date && date.id === currentDate.id;

                var dateClasses = "date-select";

                if (isSelected) {
                    dateClasses += " date-select-on";
                }

                return (
                    <div key={currentDate.id} className={dateClasses} onClick={() => onChangeDate({id: currentDate.id}) }>
                        {currentDate.title}
                    </div>
                );
            })}
        </div>
    )
};