import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import WinkingLogo from "../components/WinkingLogo";
import HttpServices from "../services/HttpServices";

export default() => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        verify();
    }, []);

    const verify = async() => {
        try {
            await HttpServices.post(`/postie/${params.id}/verify`, {
                verification_code: params.code
            });

            setLoading(false);
        } catch (e) {
            console.log(e);
            setError("We couldn't verify your postie");
            setLoading(false);
        }   
    };

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 50,
            maxWidth: 550
        },
        logo: {
            marginBottom: 30
        },
        message: {
            opacity: loading ? 0 : 1,
            transition: "all 0.1s ease-in-out"
        },
        buttonHolder: {
            marginTop: 50
        }
    };

    const sendAnother = () => {
        navigate("/?expert=true", {replace: true});
    }

    return (
        <div className="full-page"> 
            <div className="centered-text" style={localStyles.container}>
                <div className={loading ? "winking-loader" : ""} style={localStyles.logo}>
                    <WinkingLogo/>
                </div>
                <div style={localStyles.message}>
                    <h2>{error ? "Error" : "Thanks for verifying"}</h2>
                    <div className="subtext">{error ? error : "Thanks for verifying your email address. Everything is now set to receive your postie in the future."}</div>
                
                    <div style={localStyles.buttonHolder}>
                        <Button
                            label="Send another"
                            onClick={() => sendAnother()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};