import React from "react";

const THRESHOLD = 50;

export default({content, maxCharacters}) => {
    const thresholdLength = maxCharacters - THRESHOLD;

    if (!content || content.length === 0 || content.length < (thresholdLength)) {
        return null;
    }

    return (
        <div className="remaining-holder">
            {maxCharacters - content.length} characters left
        </div>
    );
};