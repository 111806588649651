import React from "react";
import logo from "../images/logo.svg";

const SIZE = 200;

export default({size}) => {
    const sizeToUse = size || SIZE;

    const localStyles = {
        logo: {
            width: sizeToUse,
            height: sizeToUse
        },
        container: {
            width: sizeToUse,
            height: sizeToUse,
            position: "relative"
        },
        leftLeftEye: {
            width: 9,
            height: 9,
            borderRadius: 4.5,
            backgroundColor: "#ffffff",
            position: "absolute",
            top: 79,
            left: 50,
            overflow: "hidden"
        },
        leftRightEye: {
            width: 9,
            height: 9,
            borderRadius: 4.5,
            backgroundColor: "#ffffff",
            position: "absolute",
            top: 79,
            left: 73,
            overflow: "hidden"
        },
        blinkerLeft: {
            width: 10,
            height: 5,
            backgroundColor: "#77D9E6",
            position: "absolute",
            top: -5,
            left: 0
        }, 
        tongueRight: {
            backgroundColor: "#ffffff",
            width: 8,
            height: 0,
            position: "absolute",
            left: 126,
            top: 110,
            borderBottomRightRadius: 30,
            borderBottomLeftRadius: 30,
            transform: "rotate(-2deg)"
        },
        winkRight: {
            backgroundColor: "#62C9D8",
            opacity: 0.6,
            width: 15,
            height: 8,
            position: "absolute",
            top: 0,
            left: 0
        },
        rightLeftEye: {
            width: 9,
            height: 9,
            borderRadius: 4.5,
            backgroundColor: "#ffffff",
            position: "absolute",
            top: 79,
            left: 115,
            overflow: "hidden"
        },
    };

    return (
        <div style={localStyles.container}>
            <img src={logo} style={localStyles.logo}/>
            <div style={localStyles.leftLeftEye}>
                <div style={localStyles.blinkerLeft} className="blinker"></div>
            </div>
            <div style={localStyles.leftRightEye}>
                <div style={localStyles.blinkerLeft} className="blinker"></div>
            </div>
            <div style={localStyles.tongueRight} className="tongue-out">

            </div>
            
            
        </div>
        
    );
};