import React, {useState, useRef, useCallback} from "react";
import { useEditable } from "use-editable";
import useWindowSize from "../hooks/useWindowSize";

const LINE_HEIGHT = 60;
const MOBILE_LINE_HEIGHT = 40;
const DRAWN_LINE_OFFSET = 6;
const MOBILE_SCREEN_THRESHOLD = 600;

export default({content, onChangeContent}) => {
    const [measuredSize, setMeasuredSize] = useState({height: 100, width: 100});
    const editableRef = useRef(null);
    const windowSize = useWindowSize();

    if (onChangeContent) {
        useEditable(editableRef, onChangeContent);
    }

    const measuredRef = useCallback((node) => {
        if (node !== null) {
          const m = node.getBoundingClientRect();
          setMeasuredSize({
              height: m.height,
              width: m.width
          });
        }
    }, []);

    const localStyles = {
        postcardText: {
            height: measuredSize.height
        }
    };

    const getLineHeight = () => {
        if (windowSize.width < MOBILE_SCREEN_THRESHOLD) {
            return MOBILE_LINE_HEIGHT;
        }

        return LINE_HEIGHT;
    };

    const getPostcardLines = () => {
        var pos = getLineHeight();
        var i = 0;

        var lines = [];
        
        while (pos < measuredSize.height) {
            lines.push({
                top: (i * getLineHeight()) + getLineHeight() - DRAWN_LINE_OFFSET
            });

            i++;
            pos += getLineHeight();
        }

        return lines;
    };

    return (
        <div ref={measuredRef} className="postcard-text-container">
            <div className="postcard-lines">
                {getPostcardLines().map((line, index) => {
                    return (
                        <div 
                            key={index} 
                            className="postcard-line"
                            style={{
                                top: line.top
                            }}
                            >
                        </div>
                    );
                })}
                
                
            </div>
            <div ref={editableRef} className="postcard-text" style={localStyles.postcardText} spellCheck={false}>
            {content}
            </div>
        </div>
    );
};