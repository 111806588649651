import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import WinkingLogo from "../components/WinkingLogo";

export default() => {
    const navigate = useNavigate();

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 50
        },
        logo: {
            marginBottom: 30
        },
        buttonHolder: {
            marginTop: 50
        }
    };

    const sendAnother = () => {
        navigate("/?expert=true", {replace: true});
    }

    return (
        <div className="full-page"> 
            <div className="centered-text" style={localStyles.container}>
                <div style={localStyles.logo}>
                    <WinkingLogo/>
                </div>
                <h2>We're sending your postcard into the future</h2>
                <div className="subtext">But first we've sent you an email so you can confirm your address</div>
            
                <div style={localStyles.buttonHolder}>
                    <Button
                        label="Send another"
                        onClick={() => sendAnother()}
                    />
                </div>
                
            
            </div>
            

        </div>
    );
};