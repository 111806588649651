import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Done from "./pages/Done";
import Verify from "./pages/Verify";
import Preview from "./pages/Preview";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path="/preview" element={<Preview/>} />
        <Route path="/done" element={<Done/>} />
        <Route path="/verify/:id/:code" element={<Verify/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
