import React, {useState, useCallback} from "react";
import DateSelector from "./DateSelector";


export default({email, onChangeEmail, date, onChangeDate}) => {
    return (
        <div className="to-when">
            <div className="field-and-label">
                <div className="address-label">
                    To:
                </div>
                <input 
                    type="email" 
                    value={email}
                    onChange={(evt) => onChangeEmail(evt.target.value)}
                    placeholder="Enter email"
                    className="text-field"/>
            </div>
            <div className="field-and-label field-and-label-spacer field-and-label-top">
                <div className="address-label">
                    In:
                </div>
                <DateSelector
                    date={date}
                    onChangeDate={onChangeDate}
                />
                
            </div>
            

        </div>
    );
};