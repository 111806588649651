import React from "react";

import loadingIcon from "../images/loader.svg";

export default({label, loading, disabled, icon, rounded, onClick}) => {
    var content = null;

    const click = () => {
        if (!disabled) {
            onClick();
        }
    };

    if (loading) {
        content = (
            <img src={loadingIcon} className="loader"/>
        );
    } else if (label) {
        content = label;
    } else if (icon) {
        content = (
            <img src={icon} className="button-icon"/>
        );
    }

    var btnClasses = "";

    if (rounded) {
        btnClasses = "rounded-button";
    } else {
        btnClasses = "button";
    }

    if (disabled) {
        btnClasses += " disabled-button";
    }

    return (
        <div className={btnClasses} onClick={() => click()}>
            {content}
        </div>
    );
};