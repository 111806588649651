import React from "react";
import Button from "./Button";
import arrowNext from "../images/arrow_next.svg";
import arrowPrevious from "../images/arrow_previous.svg";

export default({visible, onNext, onBack, canNext, loadingNext}) => {
    const localStyles = {
        container: {
            justifyContent: onBack ? "space-between" : "flex-end"
        }
    };

    if (!visible) {
        return null;
    }

    const renderBack = () => {
        if (onBack) {
            return (
                <Button
                    rounded={true}
                    icon={arrowPrevious}
                    onClick={() => onBack()}/>
            );
        }
    };

    return (
        <div className="postcard-buttons" style={localStyles.container}>
            {renderBack()}
            <Button 
                disabled={!canNext}
                icon={arrowNext}
                loading={loadingNext}
                rounded={true}
                onClick={() => onNext()}/>
        </div>
    );
};