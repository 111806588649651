import fetch from "isomorphic-unfetch";
import config from "../config";

const STATUS_CODE_OK = 200;
const STATUS_CODE_UNAUTHORISED = 401;
const STATUS_CODE_BAD_REQUEST = 400;

const BASE_URL = config.BASE_URL;
const APP_TOKEN = "web";

const APPLICATION_JSON_CONTENT_TYPE = "application/json";

export default {
  token: null,

  getBaseUrl() {
    return BASE_URL;
  },

  clearUserAndToken() {
    this.token = null;
    window.sessionStorage.clear();
  },

  setUser(user) {
    window.sessionStorage.user = JSON.stringify(user);
  },

  setUserAndToken(user, token) {
    this.token = token;
    window.sessionStorage.token = token;
    window.sessionStorage.user = JSON.stringify(user);
  },

  async login(email, password) {
    const result = await this.post("/user/login", {
      email,
      password,
    });

    this.setUserAndToken(result.user, result.token);

    return result;
  },

  getToken() {
    return this.token;
  },

  getUser() {
    if (!window.sessionStorage.user) {
      return null;
    }

    try {
      return JSON.parse(window.sessionStorage.user);
    } catch (e) {
      return null;
    }
  },

  async createHeaders(alternativeBaseUrl) {
    if (alternativeBaseUrl) {
      return {
        "Content-Type": APPLICATION_JSON_CONTENT_TYPE,
      };
    }

    const headers = {};

    if (window.sessionStorage.token) {
      headers["x-app-token"] = APP_TOKEN;
      headers["x-user-token"] = window.sessionStorage.token;
    }

    return headers;
  },


  async get(url, alternativeBaseUrl) {
    let res;

    try {
      res = await fetch((alternativeBaseUrl || BASE_URL) + url, {
        method: "GET",
        headers: await this.createHeaders(alternativeBaseUrl),
      });

      return this.handleResponse(res);
    } catch (e) {
      throw Error("An unxpected error occurred", e);
    }
  },

  async handleResponse(res) {
    // Get data back
    const data = await res.json();

    if (res.status === STATUS_CODE_BAD_REQUEST) {
      throw Error("Unexpected Error");
    }

    if (res.status === STATUS_CODE_UNAUTHORISED) {
      // TODO UNDO THIS
      debugger;
      window.location.href = "/login";
      return;
    }

    if (res.status !== STATUS_CODE_OK) {
      if (Array.isArray(data)) {
        if (data.length > 0 && data[0].error) {
          throw Error(data[0].error);
        }
      } else if (data.error) {
        throw Error(data.error);
      }

      throw Error("Unexpected Error");
    } else {
      return data;
    }
  },

  async post(url, body, alternativeBaseUrl) {
    return this.makeBodiedRequest(url, "POST", body, alternativeBaseUrl);
  },

  async patch(url, body, alternativeBaseUrl) {
    return this.makeBodiedRequest(url, "PATCH", body, alternativeBaseUrl);
  },

  async delete(url, body, alternativeBaseUrl) {
    return this.makeBodiedRequest(url, "DELETE", body, alternativeBaseUrl);
  },

  async makeBodiedRequest(url, method, body, alternativeBaseUrl) {
    let res;

    try {
      res = await fetch((alternativeBaseUrl || BASE_URL) + url, {
        method,
        headers: await this.createHeaders(alternativeBaseUrl),
        body: JSON.stringify(body),
      });

      return this.handleResponse(res);
    } catch (e) {
      console.log(e);
      throw Error("An unxpected error occurred");
    }
  },

  stringifyNoQuotes(obj) {
    const jsonStr = JSON.stringify(obj);
    // eslint-disable-next-line
    return jsonStr.replace(/\"([^(\")"]+)\":/g, "$1:");
  },

  stringifyAndEscape(obj) {
    const jsonStr = JSON.stringify(obj);
    return JSON.stringify(jsonStr);
  },
};
