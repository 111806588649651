import moment from "moment";
import React, {useState} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import About from "../components/About";
import PostcardButtons from "../components/PostcardButtons";
import PostcardContent from "../components/PostcardContent";
import RemainingCharacters from "../components/RemainingCharacters";
import ToAndWhen from "../components/ToAndWhen";

import logo from "../images/logo.svg";
import HttpServices from "../services/HttpServices";

const PAGE_CONTENT = "content";
const PAGE_TO_WHEN = "to_when";
const PAGE_ABOUT = "about";

const MAX_CONTENT_LENGTH = 250;

export default() => {

    let [searchParams] = useSearchParams();
    
    const expertFlag = searchParams.get("expert");

    const FIRST_PAGE = expertFlag ? PAGE_TO_WHEN : PAGE_ABOUT;

    const [page, setPage] = useState(FIRST_PAGE);
    const [content, setContent] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState({id: "years"});
    const [loadingNext, setLoadingNext] = useState(false);
    const [createdPostie, setCreatedPostie] = useState(false);
    const navigate = useNavigate();

    const next = () => {
        if (page === PAGE_TO_WHEN) {
            setPage(PAGE_CONTENT);
        } else if (page === PAGE_CONTENT) {
            sendPostie();
        } else if (page === PAGE_ABOUT) {
            setPage(PAGE_TO_WHEN);
        }
    };

    const wait = async(timeout) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, timeout);
        });
    };

    const sendPostie = async() => {
        setLoadingNext(true);
       
        await HttpServices.post(`/postie`, {
            content: content,
            email: email,
            date: moment().add(1, date.id).format("YYYY-MM-DD")
        });

        await wait(1000);

        setCreatedPostie(true);

        await wait(1000);

        navigate("/done", {replace: false});

    };

    const back = () => {
        setLoadingNext(false);
        if (page === PAGE_CONTENT) {
            setPage(PAGE_TO_WHEN);
        } else if (page === PAGE_TO_WHEN && FIRST_PAGE === PAGE_ABOUT) {
            setPage(PAGE_ABOUT);
        }
    };

    const canNext = () => {
        if (page === PAGE_CONTENT && content.length > 5) {
            return true;
        } else if (page === PAGE_TO_WHEN && email.length > 0 && email.indexOf("@") > 0 && email.indexOf(".") > 0) {
            return true;
        } else if (page === PAGE_ABOUT) {
            return true;
        }

        return false;
    };

    const getPostcardClasses = () => {
        var classes = "postcard";

        if (loadingNext) {
            classes += " postcard-minimising";
        }

        if (createdPostie) {
            classes += " postcard-offscreen";
        }

        return classes;
    };

    const onChangeContent = (val) => {
        if (val.length >= MAX_CONTENT_LENGTH) {
            setContent(val.substr(0, MAX_CONTENT_LENGTH));
        } else {
            setContent(val);
        }
        
    };

    const renderContent = () => {
        if (page === PAGE_CONTENT) {
            return (
                <PostcardContent
                    content={content}
                    onChangeContent={(val) => onChangeContent(val)}
                />
            );
        } else if (page === PAGE_TO_WHEN) {
            return (
                <ToAndWhen
                    date={date}
                    onChangeDate={(date) => setDate(date)}
                    email={email}
                    onChangeEmail={(val) => setEmail(val)}
                />
            );
        } else if (page === PAGE_ABOUT) {
            return (
                <About/>
            );
        }
    };

    var backAction = null;
    
    if (FIRST_PAGE === PAGE_ABOUT) {
        if (page !== PAGE_ABOUT) {
            backAction = () => back();
        }
    } else {
        if (page !== PAGE_TO_WHEN) {
            backAction = () => back();
        }
    }

    return (
        <div className="full-page">  
            <div className={getPostcardClasses()}>
                <div className="to-section">
                    <img alt="Logo" className="big-logo" src={logo}/>
                </div>
                {renderContent()}
                <PostcardButtons
                    visible={!loadingNext}
                    loadingNext={loadingNext}
                    onBack={backAction}
                    onNext={() => next()}
                    canNext={canNext()}
                />
                <RemainingCharacters
                    content={content}
                    maxCharacters={MAX_CONTENT_LENGTH}
                />
            </div>
        </div>
    )
};