import React from "react";

export default() => {
    const localStyles = {
        container: {
            marginTop: 50,
            paddingLeft: 10,
            paddingRight: 10
        }
    };

    return (
        <div style={localStyles.container} className="about-holder">
            <h2>Send email postcards to future you</h2>
            <div className="subtext">Hi there 👋 This website lets you send email postcards to your future self. </div>
            <div className="subtext">Why? Well it's a great technique for visualising objectives and things you want to do.</div>
            <div className="subtext">It's completely free to use so hit the next button below to get started (and don't worry we only use your email to send you your postcard in the future, never for anything else).</div>
        </div>
    )  
};